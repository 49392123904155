import React, { useState, useEffect } from "react";
import {
  withStyles,
  makeStyles,
  Box,
  Typography,
  Button,
  IconButton,
  Grid,
  InputAdornment,
  InputBase,
  Modal,
  Backdrop,
  Fade,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import axios from "axios";
import apiConfig from "src/config/APIConfig";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import ProgressLoaderCircular from "src/component/ProgressLoaderCircular";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    background: "#1a1a2a",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 4),
    border: "none",
    // width: "100%",
    border: "1px solid white",
    borderRadius: "10px",
    maxWidth: "500px",
  },
  btns: {
    margin: "0px 10px",
  },
  hoverSection: {
    "&:hover": {
      background: "rgba(255, 255, 255, 0.03) !important",
    },
  },
  table: {
    minWidth: 700,
    background: "transparent",
    borderTop: "1px solid #9B998A",
  },
  mainSection: {
    background: "#383858",
    marginTop: "4rem",
    paddingTop: "5rem",
    paddingBottom: "5rem",

    "& h3": {
      color: "#fff",
      paddingBottom: "1.8rem",
    },
  },
  gridSectio: {
    // order: "1",
    "@media(max-width:952px)": {
      order: "3",
    },
  },
  gridSectio1: {
    color: "#fff",
    // order: "2",
    "@media(max-width:952px)": {
      order: "1",
    },
  },
  gridSectio2: {
    color: "#fff",
    // order: "3",
    "@media(max-width:952px)": {
      order: "2",
    },
  },
  gridSectio3: {
    // order: "4",
    "@media(max-width:952px)": {
      order: "4",
    },
  },
  searchSection: {
    marginTop: "1.7rem",
    "@media(max-width:600px)": {
      marginTop: ".7rem",
    },
  },
  field: {
    height: "52px !important",
    border: "1px solid #52565c",
    padding: "10px 10px 10px 20px",
    borderRadius: "4px",

    fontSize: "14px",
  },
  btnSection: {
    marginTop: "1.3rem",
  },
  IconsProperties: {
    color: "white",
    border: "1px solid rgba(255, 255, 255, 0.2)",
    padding: "10px",
    borderRadius: "8px",
    height: "18px",
    width: "20px",
    textAlign: "center",
  },
}));

export default function ModalDelete({
  open1,
  onClose,
  type,
  varid,
  buttonType,
}) {
  const history = useHistory();

  const classes = useStyles();
  const [var1, setvar] = useState("");
  const [apiCall, setApiCall] = useState(false);
  const [openclose, setOpenClose] = useState(false);
  const [_banner, setBanner] = useState();
  const [_delete, setDelete] = useState();

  return (
    <div>
      {" "}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open1}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open1}>
          <Box className={classes.paper}>
            <Box textAlign="center">
              <ExitToAppIcon style={{ color: "red", fontSize: "30px" }} />
            </Box>
            <Box textAlign="center">
              <Typography
                variant="h5"
                style={{ marginTop: "5px", paddingBottom: "5px" }}
              >
                {" "}
                LOGOUT
              </Typography>
              <Typography variant="caption">
                Are you sure you want to proceed for LOGOUT?
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={4}
            >
              <Button
                variant="contained"
                className={classes.btns}
                onClick={onClose}
              >
                No
              </Button>
              <Button
                variant="contained"
                className={classes.btns}
                onClick={() => history.push("./")}
                // disabled={apiCall}
              >
                Yes
              </Button>
              {apiCall && <ProgressLoaderCircular />}
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
