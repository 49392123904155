import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Grid, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  content: {
    height: "100vh",
    overflowX: "auto",
    background: "url(/images/background.png)",

    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    color: "#fff",

    [theme.breakpoints.down("sm")]: {
      height: "auto",
      overflow: "unset",
      paddingTop: "10px",
    },
  },
  left: {
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: 300,
    },
    [theme.breakpoints.down("xs")]: {
      height: 360,
    },
  },
  mainbox: {
    boxShadow: "0px 30px 30px rgba(0, 0, 0, 0.3)",
    backgroundColor: "#302F35",
    backdropFilter: "blur(42px)",
    // backgroundColor: "#000",
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid container>
      {/* <Grid item xs={12} sm={12} md={5} className={classes.left}>
        <Box
          className={classes.mainbox}
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Box className="signupmain">
            <img
              src="/images/login.png"
              style={{ width: "80%", maxWidth: 480, margin: "20px 0" }}
              alt=""
              onClick={() => history.push("/")}
            />
          </Box>
        </Box>
      </Grid> */}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box className={classes.content}>{children}</Box>
      </Grid>
    </Grid>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
