import React, { useState, useEffect, useContext } from "react";
import NotificationsList from "src/component/notifications";
import { Grid, Box, Typography, Link } from "@material-ui/core";
import Page from "src/component/Page";
import Axios from "axios";
import ApiConfig from "src/config/APIConfig";
import { toast } from "react-toastify";
import NoDataFound from "src/DataNotFound";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { AuthContext } from "src/context/Auth";

export default function Notifications({ popUp }) {
  const [notificationList, setNotificationList] = useState([]);
  const auth = useContext(AuthContext);
  const userdata = auth.userData ? auth.userData : "";

  const [count, setCount] = useState([]);

  // console.log("notificationList====", count.length);
  const [isLoading, setIsLoading] = useState(false);
  const getNotifictionList = async () => {
    setIsLoading(true);
    try {
      const res = await Axios.get(ApiConfig.getNotification, {
        headers: {
          authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
          role: "USER",
        },
        params: {
          fkUserId: userdata?.userId,
        },
      });
      console.log("res", res);
      if (res.data.status === 1618) {
        setCount(res.data.data.filter((data) => !data.isSeen));
        setNotificationList(res.data.data);
        setIsLoading(false);
      }
    } catch (err) {
      toast.error(err.response.data.responseMessage);
      console.error(err.response);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (userdata?.userId) {
      getNotifictionList();
    }
  }, [userdata?.userId]);

  return (
    <Page title="Notifications">
      <Box mb={3} className="bankbox1" style={{ background: "tranparent" }}>
        <Box mb={3}>
          <Box
            display="flex"
            className="notificationbox"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5" pt={4}>
              Notifications
            </Typography>
            {popUp && (
              <Typography variant="body2">
                <Link style={{ color: "#fff" }} href="/notifications">
                  View all
                </Link>
              </Typography>
            )}
          </Box>
        </Box>

        <Grid container spacing={4} sm={12} style={{ margin: "0" }}>
          {popUp
            ? notificationList.slice(0, 4).map((data, i) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    key={i}
                    style={{ padding: "15px 0" }}
                  >
                    <NotificationsList popUp={popUp} data={data} index={i} />
                  </Grid>
                );
              })
            : notificationList &&
              notificationList.map((data, i) => {
                return (
                  <Grid item xs={12} sm={12} md={12} key={i}>
                    <NotificationsList popUp={popUp} data={data} index={i} />
                  </Grid>
                );
              })}
          {!isLoading && notificationList && notificationList.length === 0 && (
            <NoDataFound />
          )}
          {isLoading && <ButtonCircularProgress />}
        </Grid>
      </Box>
    </Page>
  );
}
