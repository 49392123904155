import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import apiConfig from "src/config/APIConfig";
import { toast } from "react-toastify";
export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    window.sessionStorage.setItem("token", accessToken);
    // axios.defaults.headers.common.Authorization = `Creattur ${accessToken}`;
  } else {
    window.sessionStorage.removeItem("token");
    // delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.sessionStorage.getItem("token");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  //   const [userData, setUserBalanceData] = useState({});
  const [userData, setUserData] = useState();
  const token = window.sessionStorage.getItem("token");

  let data = {
    userLoggedIn: isLogin,
    userData,
    getProfileHandler: (token) => getProfileHandler(token),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };
  const getProfileHandler = async (token) => {
    try {
      const res = await axios.get(apiConfig.supargetprofile, {
        headers: {
          token: token,
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.status === 200) {
        setUserData(res.data.result);
        setIsLogin(true);
      } else {
        setIsLogin(false);
      }
    } catch (error) {
      // if (error.response.data.responseCode === 401) {
      //   setIsLogin(false);
      //   toast.error(error.response.data.message);
      // }
      console.log("ERROR", error.response);
    }
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("token") != undefined || window.sessionStorage.getItem("token") != '') {
      getProfileHandler(window.sessionStorage.getItem("token"));
    } else {
      console.log('erioteriotreoty')
      setIsLogin(false);
      setUserData();
    }
  }, [window.sessionStorage.getItem("token")]);

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
