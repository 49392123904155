import React from "react";
import {
  Box,
  Fade,
  Modal,
  Backdrop,
  Button,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    border: "1px solid white",
    padding: "32px 32px 32px",
    maxWidth: "500px",
    background: "#1a1a2a",
    boxShadow: "0 0 1px 0 rgb(0 0 0 / 31%), 0 4px 6px -2px rgb(0 0 0 / 25%)",
    borderRadius: "10px",
  },
  btns: {
    margin: "0px 10px",
  },
}));

export default function AlertDialog({
  open,
  handleClose,
  title,
  desc,
  confirmationHandler,
}) {
  const classes = useStyles();
  return (
    <>
      <Box>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box className={classes.paper}>
              <Box textAlign="center">
                <ExitToAppIcon style={{ color: "red", fontSize: "30px" }} />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h5"
                  style={{ fontWeight: 600, marginBottom: "10px" }}
                >
                  LOGOUT
                </Typography>
                <Typography variant="caption">
                  Are you sure you want to proceed for LOGOUT?
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={4}
              >
                <Button
                  variant="contained"
                  className={classes.btns}
                  onClick={handleClose}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  className={classes.btns}
                  onClick={() => {
                    handleClose();
                    confirmationHandler();
                  }}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </Box>
    </>
  );
}
