import React from "react";
import { makeStyles, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    left: 0,
    padding: theme.spacing(3),
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 2000,
  },

  loader: {
    width: 800,
    maxWidth: "100%",
    margin: "auto",
  },
}));

export default function PageLoading() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box width={400}>
        {/* <LinearProgress /> */}
        <img
          className={classes.loader}
          src="/images/loader123.gif"
          alt="loader"
        />
      </Box>
    </div>
  );
}
