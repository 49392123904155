import { Typography, Box, IconButton, Link } from "@material-ui/core";
import React, { useState } from "react";
import { FaChevronRight } from "react-icons/fa";

import { Link as RouterLink } from "react-router-dom";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import moment from "moment";

export default function NotificationsList({ data, popUp }) {
  const [open, setOpen] = useState(false);
  console.log("datanotificatio", data);

  const confirmationHandler = () => {
    setOpen(false);
  };
  return (
    <Box
      className="notication-list"
      style={
        popUp
          ? {
              borderBottom: "1px solid #ccc",
              padding: "0px 5px 7px",
              position: "relative",
              color: "#000",
            }
          : {
              borderBottom: "1px solid #ccc",
              padding: "0px 10px 15px",
              position: "relative",
              color: "#000",
            }
      }
    >
      {open && (
        <ConfirmationDialog
          open={open}
          handleClose={() => setOpen(false)}
          title={"title"}
          desc={"desc"}
          confirmationHandler={confirmationHandler}
        />
      )}
      <Box display="flex">
        <Box>
          <IconButton
            style={{
              backgroundColor: "#EE786C",
              color: "#fff",
              marginRight: "12px",
            }}
          >
            <FaChevronRight size={14} />
          </IconButton>
        </Box>
        <Link
          to={{
            pathname: "/notifications",
            state: {
              data: data,
            },
          }}
          style={{ textDecoration: "none", width: "100%" }}
          component={RouterLink}
        >
          <Box style={{ width: "calc(100% - 50px)" }}>
            <Box className="rightPosition d-flex" style={{ marginTop: "10px" }}>
              <Typography variant="subtitle2" pt={2}>
                {/* {data?.createdAt} */}
                {data?.createdAt
                  ? moment(data.createdAt).format("MMMM Do YYYY, h:mm:ss a ")
                  : "0"}

                {/* <FaChevronRight size={14} style={{ marginLeft: "15px" }} /> */}
              </Typography>
            </Box>
            <Box className="width120">
              <Typography variant="subtitle2" className="extra-bold">
                {data?.fkUserId}
              </Typography>
              <Typography variant="subtitle2" pt={2} className="ellispsys">
                {data?.message}
              </Typography>
            </Box>
          </Box>
        </Link>
      </Box>
    </Box>
  );
}
