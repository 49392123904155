import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    "& h6": {
      background: theme.palette.background.dark1,
      color: theme.palette.text.black,
      height: "10vh",
    },
  },
}));
export default function NoDataFound() {
  const classes = useStyles();
  return (
    <Box className={classes.mainBox}>
      <Typography
        variant="h6"
        style={{
          textAlign: "center",
          paddingTop: "40px",
          marginLeft: "20px",
          fontSize: "16px",
          fontWeight: "100",
        }}
      >
        No Data Found
      </Typography>
    </Box>
  );
}
