const baseurl = "https://node-digitalstore.mobiloitte.com/";
let static1 = `${baseurl}/api/v1/static`;
let user = `${baseurl}/api/v1/user`;
let nft = `${baseurl}/api/v1/nft`;
let admin = `${baseurl}/api/v1/admin`;
export const pubBlockchainUrl = "https://digital-asset.mobiloitte.org/";

const apiConfig = {
  changePasswordUser: `${user}/changePasswordUser`,
  getProfile: `${user}/getProfile`,
  VerifyOTP: `${admin}/verifyOTP`,
  resetPassword: `${admin}/resetPassword`,
  ForgotPassword: `${admin}/forgotPassword`,
  editProfile: `${admin}/editProfile`,
  editUser: `${admin}/editUser`,
  adminLogin: `${admin}/adminLogin`,
  listAllUser: `${admin}/listAllUser`,
  listOrganization: `${admin}/listOrganization`,
  editOrganization: `${admin}/editOrganization`,
  addSubAdmin: `${admin}/addSubAdmin`,
  supargetprofile: `${admin}/getProfile`,
  deleteUser: `${admin}/deleteUser`,
  deleteOrganization: `${admin}/deleteOrganization`,
  deleteInfocategory: `${admin}/deleteInfocategory`,
  addRoles: `${admin}/addRoles`,
  editRole: `${admin}/editRole`,
  deleteRole: `${admin}/deleteRole`,
  listRoles: `${admin}/listRoles`,
  viewRoles: `${admin}/viewRoles`,
  viewPermission: `${admin}/viewPermission`,
  addPermission: `${admin}/addPermission`,
  listPermission: `${admin}/listPermission`,
  editPermission: `${admin}/editPermission`,
  deletePermission: `${admin}/deletePermission`,

  register: `${user}/userRegistration`,
  organizationList: `${admin}/listOrganization`,
  addOrganization: `${admin}/addOrganization`,
  viewOrganization: `${admin}/viewOrganization`,
  ipfsUpload: `${nft}/ipfsUpload`,
  uploadFile: `${admin}/uploadFile`,
  updateAsset: `${nft}/updateAsset`,
  addCategory: `${admin}/addCategory`,
  viewCategory: `${admin}/viewCategory`,
  listCategory: `${admin}/listCategory`,
  dashBoard: `${admin}/dashBoard`,
  adminDashBoard: `${admin}/adminDashBoard`,
  addChannel: `${admin}/addChannel`,
  viewChannel: `${admin}/viewChannel`,
  listChannel: `${admin}/listChannel`,

  deleteCategory: `${admin}/deleteCategory`,
  editCategory: `${admin}/editCategory`,
  viewUser: `${admin}/viewUser`,
  addStaticContent: `${static1}/addStaticContent`,
  StaticContent: `${static1}/StaticContentList`,
  VeiwStaticContent: `${static1}/viewStaticContent`,
  editStaticContent: `${static1}/editStaticContent`,
  editBanner: `${static1}/editBanner`,

  addFAQ: `${static1}/addFAQ`,
  viewFAQ: `${static1}/viewFAQ`,
  editFAQ: `${static1}/editFAQ`,
  faqList: `${static1}/faqList`,
  editBanner: `${static1}/editBanner`,
  addBanner: `${static1}/addBanner`,
  bannerList: `${static1}/bannerList`,
  viewbanner: `${static1}/viewbanner`,
  deleteBanner: `${static1}/deleteBanner`,
  activeBlockBanner: `${static1}/activeBlockBanner`,
  blockUnblockUser: `${admin}/blockUnblockUser`,
};

export default apiConfig;
