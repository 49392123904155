import React, { useEffect, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";

import PropTypes from "prop-types";
import {
  Box,
  Button,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";
import Logo from "src/component/Logo";
import { FaUsersCog, FaQq } from "react-icons/fa";
import { GiVerticalBanner } from "react-icons/gi";
import { CgOrganisation, CgChanel } from "react-icons/cg";
import { MdOutlineContentPaste } from "react-icons/md";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import NavItem from "./NavItem";
import DashboardIcon from "@material-ui/icons/Dashboard";
import CategoryIcon from "@material-ui/icons/Category";

import ViewQuiltIcon from "@material-ui/icons/ViewQuilt";
import ModalLogout from "../../../component/MoladLogout";
const sections = [
  {
    items: [
      {
        title: "DASHBOARD",
        icon: DashboardIcon,
        href: "/dashboard",
      },
      {
        title: "USER MANAGEMENT",
        icon: FaUsersCog,
        href: "/UserManagement",
      },
      {
        title: "ROLE MANAGEMENT",
        icon: FaUsersCog,
        href: "/Role",
      },

      {
        title: "BANNER MANAGEMENT",
        icon: GiVerticalBanner,
        href: "/BannerManagement",
      },
      {
        title: "CATEGORY MANAGEMENT",
        icon: CategoryIcon,
        href: "/CategoryManagement",
      },
      {
        title: "ORGANIZATION MANAGEMENT",
        icon: CgOrganisation,
        href: "/OrganizationManagement",
      },

      {
        title: "CHANNEL MANAGEMENT",
        icon: CgChanel,
        href: "/Channel",
      },
      // {
      //   title: "PROFILE",
      //   icon: PersonIcon,
      //   href: "/Profile",
      // },
      {
        title: "STATIC MANAGEMENT",
        icon: MdOutlineContentPaste,
        href: "/StaticContent",
      },
      {
        title: "FAQ MANAGEMENT",
        icon: FaQq,
        href: "/faq",
      },
    ],
  },
];
const sectionsLogout = [
  {
    title: "LOGOUT",
    icon: ViewQuiltIcon,
    href: "/StaticContent",
  },
];

// var getTitle = sections[0].items[8].title;
// console.log("getTitle", getTitle);
// console.log(
//   "sectionskjfhksjjaf",
//   sections[0].items.filter((data) =>
//     data.title === "LOGOUT" ? "hello" : "byy"
//   )
// );
const sectionsBelow = [
  {
    items: [
      {
        //     title: "TERM & CONDITION",
        // href: "/terms",
      },
      //   {
      //     title: "PRIVACY POLICY",
      //     href: "/privacy-policy",
      //   },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

// sidebar style
const useStyles = makeStyles((theme) => ({
  logoutbtn: {
    display: "flex",
    padding: "20px 24px",
    paddingRight: "130px",
  },
  mobileDrawer: {
    width: 256,
    // background: "linear-gradient(#000, #362e9e)",
  },
  desktopDrawer: {
    width: 270,
    top: 0,
    height: "100%",
    // background: "linear-gradient(#000, #362e9e)",
    backgroundImage: `url(${"../images/background.png"})`,
    boxShadow: " 0 0.1rem 0.7rem rgb(0 0 0 / 10%)",
    borderRight: "1px solid #8080804d",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  LogoutBox: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #80808080",
    "&:hover": {
      background: "rgba(255, 255, 255, 0.03)",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  const history = useHistory();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);
  const [btnType, setBtnType] = useState();
  const [open, setOpen] = useState(false);
  const [var1, setvar] = useState();

  const handleClickOpen = (id, type) => {
    setvar(id);
    setBtnType(type);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Hidden mdDown>
        <Box padding={0}>
          {/* Logo style cantent */}
          <Logo
            onClick={() => history.push("/")}
            alt="Logo"
            style={{
              paddingTop: "20px",
              // paddingLeft: "15px",
              width: "260px",
              cursor: "pointer",
            }}
          />
          <hr />
        </Box>
      </Hidden>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box my={3}>
          {sections.map((section, i) => (
            <>
              <List
                key={`menu${i}`}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname,
                })}
                {/* <Link to="/"> */}
                <Box
                  className={classes.LogoutBox}
                  onClick={() => handleClickOpen()}
                >
                  <Button className={classes.logoutbtn} style={{}} fullwidth>
                    <ExitToAppIcon /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; LOGOUT
                  </Button>
                </Box>
                {/* </Link> */}
              </List>
            </>
          ))}
          <ModalLogout onClose={handleClose} open1={open} />
        </Box>
        <Box className="side_nev_Bottom">
          {sectionsBelow.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {section.items.map((itemList, i) => {
                return (
                  <>
                    <Button
                      fullWidth
                      key={i}
                      style={{
                        justifyContent: "start",
                        paddingLeft: 25,
                        borderRadius: 0,
                        textTransform: "capitalize",
                        fontSize: "12px !important",
                        fontWeight: "700",
                      }}
                      onClick={() => history.push(itemList.href)}
                    >
                      {itemList.title}
                    </Button>
                  </>
                );
              })}

              {/* {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })} */}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
