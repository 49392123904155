import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import { softShadows, strongShadows } from "./shadows";
import typography from "./typography";

const baseOptions = {
  direction: "ltr",
  typography,
  overrides: {
    MuiPaginationItem: {
      outlined: {
        backgroundColor: "#322846",
        border: "1px solid white !important",
        color: "white !important",
      },
    },
    // .MuiFormControl-marginNormal
    MuiAccordionSummary: {
      content: {
        margin: "0 !important",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        background: "none !important",
      },
    },
    MuiOutlinedInput: {
      adornedEnd: {
        height: "52px !important",
      },
    },
    MuiTabScrollButton: {
      root: {
        color: "white !important",
      },
    },
    MuiAppBar: {
      colorDefault: {
        background: "url(/images/background.png)",
      },
    },
    MuiIconButton: {
      root: {
        color: "white !important",
      },
    },

    MuiTableContainer: {
      root: {
        background: "rgba(255, 255, 255, 0.03) !important",
        padding: "20px",
        marginTop: "10px",
      },
    },
    MuiDialog: {
      paperWidthSm: {
        backgroundImage: "url(./images/background.png)",
        border: "1px solid white",
        borderRadius: "10px",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      },
    },
    MuiDialogActions: {
      root: {
        marginRight: "14px",
      },
    },
    MuiDialogContent: {
      root: {
        overflowY: "none",
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: "12px !important",
        marginBottom: "12px !important",
      },
      root: {
        border: "0",
        width: "100%",
        margin: "0",
        display: "inline-flex",
        padding: "0",
        position: "relative",
        minWidth: "0",
        flexDirection: "column",
        verticalAlign: "top",
      },
      marginDense: {
        marginTop: "7px !important",
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden",
      },
      colorPrimary: {
        background: "#3f3572 !important",
        height: "6px",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiList: {
      padding: {
        paddingTop: "0px",
        paddingBottom: "8px",
      },
    },
    MuiListItem: {
      root: {
        paddingBottom: "0px",
        paddingTop: "0x",
      },
      gutters: {
        paddingLeft: "0px",
        paddingRight: "0px",
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.075)",
      },
    },
    MuiTableCell: {
      alignRight: {
        textAlign: "left",
      },
      root: {
        borderBottom: "none",
        padding: "16px 16px 16px 16px",
        textAlign: "center",
      },
      body: {
        color: "#fff",
      },
      head: {
        color: "#fff",
        backgroundColor: "#000",
        fontSize: "13px !important",
        paddingTop: "20px",
        fontWeight: "500 !important",
      },
    },
    MuiTypography: {
      colorPrimary: {
        color: "#000",
      },
      caption: {
        color: "#A7A1A1",
        fontSize: "12px",
      },
      subtitle2: {
        color: "#fff",
      },
    },
    MuiTab: {
      textColorPrimary: {
        color: "#fff",
        fontSize: "16px",

        "&.Mui-selected": {
          color: "#fff",
          fontSize: "16px",
        },
      },
    },
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: "none",
        },
        "&:after": {
          borderBottom: "none",
        },
      },
    },
    MuiButton: {
      containedPrimary: {
        fontSize: "14px",
        lineHeight: "1.67",
        letterSpacing: "1px",
        textTransform: "uppercase",
        WebkitTextDecoration: "none",
        textDecoration: "none",
        padding: "14px 18px",
        WebkitTransition: "350ms",
        transition: "350ms",
        cursor: "pointer",
        color: "#fff",

        backgroundColor: "transparent",
        border: "1px solid white",
        borderRadius: "5px",
        "&:hover": {
          // backgroundColor: "#ff1919",
        },
      },

      containedSecondary: {
        fontSize: "14px",
        lineHeight: "1.67",
        letterSpacing: "1px",
        textTransform: "uppercase",
        WebkitTextDecoration: "none",
        textDecoration: "none",
        padding: "14px 18px",
        WebkitTransition: "350ms",
        transition: "350ms",
        cursor: "pointer",
        color: "#fff",
        backgroundColor: "#ff1919",
        border: "1px solid white",
        borderRadius: "5px",
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
      root: {
        color: "#fff",
      },
      contained: {
        color: "#000",
        backgroundColor: "#fff",
        "&.Mui-disabled": {
          // background:"red !important",
          color: "white !important",
          // borderBottom: "none !important",
        },
      },

      outlined: {
        border: "1px solid #797979",
        color: "white !important",
      },
      outlinedSizeSmall: {
        width: "140px",
        height: "42px",
        borderRadius: "9px",
        fontSize: "14px",
      },
      outlinedSecondary: {
        color: "#FDB95A",
        border: "1px solid #FDB95A",
      },
    },
    MuiTableRow: {
      root: {
        // backgroundColor: " #2A292E",
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "#302F35",
        color: "#fff",
      },
      outlined: {
        backgroundColor: "#2A292E",
        border: "1px solid #797979",
      },
    },
    PrivateTabIndicator: {
      colorPrimary: {
        backgroundColor: "white",
      },
    },
    MuiOutlinedInput: {
      root: {
        height: "52px !important",
        "& .Mui": {
          error: {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          },
        },
        "&:hover": {
          "& .MuiOutlinedInput-notchedOutline": {
            // borderColor: "#ffffff",
          },
        },
      },

      notchedOutline: {
        // border: "1px solid #797979",
        borderColor: "white !important",
      },
    },

    MuiFormLabel: {
      root: {
        color: "#fff",
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: "0px",
        marginRight: "0px",
        fontSize: "12px !important",
      },
    },

    MuiPickersCalendarHeader: {
      iconButton: {
        background: "none !important",
      },
      dayLabel: {
        color: "#fff",
      },
    },
    MuiPickersDay: {
      daySelected: {
        background: "transparent !important",
        color: "black",
        border: "1px solid white",
      },
      day: {
        color: "#fff",
      },
    },
    MuiOutlinedInput_root_Mui_error__MuiOutlinedInput_notchedOutline: {
      borderColor: "none !important",
    },
  },
  MuiOutlinedInput: {
    root: {
      Mui: {
        error: {
          notchedOutline: {
            borderColor: "#white",
          },
        },
      },
    },
  },
};

const themesOptions = [
  {
    name: "LIGHT",
    overrides: {
      MuiInputBase: {
        input: {
          font: "inherit",
          color: "#fff",
          width: "100%",
          // border: "1px solid #fff",
          borderRadius: "5px",
          height: "1.1876em",
          margin: "0",
          display: "block",
          // padding: "16px 0 8px",
          minWidth: "0",
          background: "none",
          boxSizing: "content-box",
          animationName: "mui-auto-fill-cancel",
          letterSpacing: "inherit",
          animationDuration: "10ms",
          WebkitTapHighlightColor: "transparent",
          "&::placeholder": {
            opacity: 1,
            color: "#fff",
          },
        },
        // root:{
        //   border:"1px solid"
        // },
        inputMultiline: {
          height: "40px",
        },
      },
    },
    palette: {
      type: "light",
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        default: colors.common.white,
        dark: "#f4f6f8",
        paper: colors.common.white,
      },
      primary: {
        main: "#fff",
      },
      secondary: {
        main: "#FF9933",
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
      },
    },
    shadows: softShadows,
  },
  {
    name: "DARK",
    palette: {
      type: "dark",
      action: {
        active: "rgba(255, 255, 255, 0.54)",
        hover: "rgba(255, 255, 255, 0.04)",
        selected: "rgba(255, 255, 255, 0.08)",
        disabled: "rgba(255, 255, 255, 0.26)",
        disabledBackground: "rgba(255, 255, 255, 0.12)",
        focus: "rgba(255, 255, 255, 0.12)",
      },
      background: {
        default: "#282C34",
        dark: "#1c2025",
        paper: "#282C34",
      },
      primary: {
        main: "#1f73b7",
      },
      secondary: {
        main: "#1f73b7",
      },
      text: {
        primary: "#e6e5e8",
        secondary: "#adb0bb",
      },
    },
    shadows: strongShadows,
  },
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
