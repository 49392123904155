import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
// import HomeLayout from 'src/layouts/HomeLayout';
import LoginLayout from "src/layouts/LoginLayout";
export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn")),
  },

  {
    exact: true,
    path: "/dashboard",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },
  {
    exact: true,
    path: "/chat",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/chat/index")),
  },
  {
    exact: true,
    path: "/Channel",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ChannelManage/Channel.js")),
  },
  {
    exact: true,
    path: "/viewChannel/:id",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ChannelManage/ViewChannel")),
  },
  {
    exact: true,
    path: "/AddChannel",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/ChannelManage/AddChannel.js")
    ),
  },

  {
    exact: true,
    path: "/Role",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/RoleManagement/ListRoles.js")),
  },
  {
    exact: true,
    path: "/viewRole/:id",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/RoleManagement/ViewRole")),
  },
  {
    exact: true,
    path: "/AddRole",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/RoleManagement/AddRole.js")
    ),
  },
  {
    exact: true,
    path: "/EditRole/:id",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/RoleManagement/EditRole")),
  },
  {
    exact: true,
    path: "/VerifyOtp",
    guard: true,
    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/auth/forget-password/ResetPassword/VerifyOtp")
    ),
  },
  {
    exact: true,
    path: "/edit-banner/:id",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/BannerManagment/EditBanner")),
  },
  {
    exact: true,
    path: "/edit-profile",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Editprofile/index")),
  },
  {
    exact: true,
    path: "/ViewUser/:id",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/UsersPage/ViewUser.js")),
  },
  {
    exact: true,
    path: "/EditUser/:id",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/UsersPage/EditUser.js")),
  },

  {
    exact: true,
    path: "/UserManagement",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/UsersPage/UserManagement.js")
    ),
  },
  {
    exact: true,
    path: "/OrganizationManagement",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/Organization/OrganizationManagement.js")),
  },
  {
    exact: true,
    path: "/AddOrganization",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/Organization/AddOrganization.js")),
  },
  {
    exact: true,
    path: "/AddUser",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/UsersPage/AddUser")),
  },
  {
    exact: true,
    path: "/ViewOrganization/:id",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/Organization/ViewOrganization.js")),
  },
  {
    exact: true,
    path: "/AddCategory",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/MyWallet/AddCategory.js")),
  },

  {
    exact: true,
    path: "/ViewCategory/:id",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/MyWallet/ViewCategory.js")),
  },

  {
    exact: true,
    path: "/BannerManagement",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/BannerManagment/BannerManagement.js")
    ),
  },
  {
    exact: true,
    path: "/AddBanner",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/BannerManagment/AddBanner.js")
    ),
  },
  {
    exact: true,
    path: "/ViewBanner/:id",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/BannerManagment/ViewBanner.js")
    ),
  },
  {
    exact: true,
    path: "/CategoryManagement",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/MyWallet/CategoryManagement.js")
    ),
  },
  {
    exact: true,
    path: "/Add_static_content",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/Staticcontent/Add_static_content")),
  },
  ,
  {
    exact: true,
    path: "/editOrganization/:id",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/Organization/EditOrganization.js")),
  },
  {
    exact: true,
    path: "/editcategory/:id",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/MyWallet/EditCategory")),
  },

  {
    exact: true,
    guard: true,
    path: "/notifications",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/notifications/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/notification-detail",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/notifications/NotificationDetails")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/Fauth",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/2FA/index")),
  },

  {
    exact: true,
    guard: true,
    path: "/kyc",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ChannelManage/KycList.js")),
  },
  {
    exact: true,
    guard: true,
    path: "/Profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ProfileComponent/index.js")),
  },
  {
    exact: true,
    guard: true,
    path: "/StaticContent",
    layout: DashboardLayout,
    component: lazy(() => import("src/Staticcontent/StaticContent.js")),
  },
  {
    exact: true,
    guard: true,
    path: "/View_Faq",
    layout: DashboardLayout,
    component: lazy(() => import("src/AddFaq/View_Faq.js")),
  },
  {
    exact: true,
    guard: true,
    path: "/Add_Faq",
    layout: DashboardLayout,
    component: lazy(() => import("src/AddFaq/Add_Faq.js")),
  },
  {
    exact: true,
    guard: true,
    path: "/faq",
    layout: DashboardLayout,
    component: lazy(() => import("src/AddFaq/List_Faq")),
  },
  {
    exact: true,
    guard: true,
    path: "/editFAQ/:id",
    layout: DashboardLayout,
    component: lazy(() => import("src/AddFaq/Edit_Faq")),
  },
  {
    exact: true,
    guard: true,
    path: "/viewcontent/:id",
    layout: DashboardLayout,
    component: lazy(() => import("src/Staticcontent/View_static_content.js")),
  },
  {
    exact: true,
    guard: true,
    path: "/editcontent/:id",
    layout: DashboardLayout,
    component: lazy(() => import("src/Staticcontent/Update_static_content.js")),
  },

  {
    exact: true,
    guard: true,
    path: "/terms",

    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/TermsAndCondition")),
  },
  {
    exact: true,
    guard: true,
    path: "/privacy-policy",

    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/PrivacyPolicy")),
  },

  {
    exact: true,
    path: "/forget-password",

    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forget-password/index")),
  },
  {
    exact: true,
    path: "/Forgot",

    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forget-password/Forgot.js")),
  },
  {
    exact: true,
    guard: true,
    path: "/instrauctions",

    component: lazy(() => import("src/views/auth/forget-password-link/index")),
  },
  {
    exact: true,
    path: "/verify-otp",

    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/auth/forget-password-link/OtpVerify")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/with",

    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/pages/UsersPage/WithdrawHistory.js")
    ),
  },
  {
    exact: true,
    path: "/reset-password",

    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },
  // {
  //   exact: true,
  //   path: "/editcontent",
  //   layout: DashboardLayout,

  //   component: lazy(() => import("src/Staticcontent/Update_static_content.js")),
  // },
  // {
  //   exact: true,
  //   path: "/sell",
  //   component: lazy(() => import("src/views/pages/Trade/Sell/")),
  // },
  {
    exact: true,
    path: "/contact-us",

    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ContactUs")),
  },
  // {
  //   exact: true,
  //   path: "/currency",
  // guard:true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Currency/")),
  // },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
